import React from 'react';
import {TabContainer, TabContent} from '../../components/Tabs/Tabs';
import {PaymentMethods} from '../PaymentMethods/PaymentMethods';
import {Profile} from '../Profile/Profile';

export const Account = () => {
  return (
    <TabContainer>
      <TabContent label="Profile" content={<Profile />} />
      <TabContent label="Payment methods" content={<PaymentMethods />} />
      <TabContent label="Recipients" content="Recipients" />
    </TabContainer>
  );
};
